import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import styled from 'styled-components'
import toast from 'react-hot-toast'
import { MediaScreens, theme, Transitions } from '../../constants/common'
import { Text } from '../text'

const palette = [
  '#6d6e4f', '#d4968c', '#f1c09c', '#86989f',
  '#d0ab97', '#7d8351', '#b17979', '#6c5a2c',
  '#deb285', '#be8e5d', '#c4c9cf', '#82a5b5',
  '#f2e6e4', '#d1cfba', '#eebdbb', '#ee9c98'
]

const Container = styled.div`
  display: grid;
  gap: ${theme.spacer.xs};
  text-align: center;
`

const PaletteGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 60px);
  grid-template-rows: repeat(4, 20px);
  justify-content: center;
  margin-top: ${theme.spacer.md};
  row-gap: ${theme.spacer.xs};

  @media screen and (min-width: ${MediaScreens.tablet}) {
    grid-template-columns: repeat(8, 60px);
    grid-template-rows: repeat(2, 20px);
  }
`

const PaletteItem = styled.div`
  background-color: ${props => props.color};
  border: 3px solid ${theme.borderColor};
  cursor: pointer;
  transition: transform ${Transitions.common};

  &:hover {
    transform: scale(1.1);
  }
`

const Title = styled(Text)`
  font-family: ${theme.fonts.title};
  font-weight: bold;
`

const Header = styled.div`
  display: grid;
  gap: ${theme.spacer.xs};
  margin: 0 auto;

  @media screen and (min-width: ${MediaScreens.tablet}) {
    max-width: 600px;
  }
`

export const Palette = () => {
  return (
    <Container>
      <Header>
        <Title size='lg'>Color Palette</Title>
        <Text>The color scheme in which we plan to have the wedding. Please refrain from white, black and saturated colors like red, purple, orange, blue, etc. If possible, choose pastel colors</Text>
        <Text size='xs'>P.S. You can click on palette to pick the color into your clipboard</Text>
      </Header>
      <PaletteGrid>
        {palette.map(color => (
          <CopyToClipboard key={color} text={color} onCopy={(text) => {
            toast(`Color ${text} was successfully copied!`, {
              style: {
                backgroundColor: text
              },
            })
          }}>
            <PaletteItem color={color} />
          </CopyToClipboard>
        ))}
      </PaletteGrid>
    </Container>
  )
}
