import React from 'react'
import { useMedia } from 'react-use'
import { MediaScreens } from '../../constants/common'
import { MobileNavigation } from './mobile'
import { DesktopNavigation } from './desktop'

export const Navigation = () => {
  const isWide = useMedia(`min-width: ${MediaScreens.tablet}`)
  return isWide ? <DesktopNavigation /> : <MobileNavigation />
}
