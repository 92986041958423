import { Link } from "react-router-dom"
import styled, { css } from "styled-components"

const linkStyles = css`
  color: #fff;
  display: inline-block;
  text-decoration: none;
  width: 100%;
`

export const ExternalLink = styled.a`
  ${linkStyles}
`

export const InternalLink = styled(Link)`
  ${linkStyles}
`
