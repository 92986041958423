import React from 'react'
import { FaCalendarDay, FaClock, FaLocationArrow } from 'react-icons/fa'
import styled from 'styled-components';
import { Text, TextSizes } from '../text';
import { MediaScreens, theme } from '../../constants/common'

const Container = styled.div`
  background-color: ${theme.detailsBg};
  display: grid;
  gap: ${theme.spacer.md};
  padding: ${theme.spacer.lg} ${theme.spacer.md};
`

const Title = styled(Text)`
  font-family: ${theme.fonts.title};
  font-weight: bold;
  text-align: center;
`

const Content = styled.div`
  display: grid;
  gap: ${theme.spacer.md};
  margin: 0 auto;
  max-width: 850px;
`

const InfoList = styled.div`
  align-items: baseline;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${theme.spacer.xs};
  text-align: center;
`

const InfoItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: calc(${TextSizes.xs} / 2);
`

const Gifts = styled.div`
  box-shadow: 0 1px 5px rgba(0,0,0,.08);
  display: grid;
  gap: ${theme.spacer.xs};
  margin-top: ${theme.spacer.xl};
  padding: ${theme.spacer.xs};
  text-align: center;

  @media screen and (min-width: ${MediaScreens.tablet}) {
    padding: ${theme.spacer.md};
  }
`

export const Details = () => {
  return (
    <Container>
      <Title size='lg'>Wedding Details</Title>
      <Content>
        <InfoList>
          <InfoItem>
            <FaCalendarDay size={TextSizes.xl} color={theme.primary} />
            <Title>Date:</Title>
            <Text size='xs'>July 9</Text>
          </InfoItem>
          <InfoItem>
            <FaClock size={TextSizes.xl} color={theme.primary} />
            <Title>Time:</Title>
            <Text size='xs'>To be defined</Text>
          </InfoItem>
          <InfoItem>
            <FaLocationArrow size={TextSizes.xl} color={theme.primary} />
            <Title>Location:</Title>
            <Text size='xs'>Saint-Petersburg <br/> st. Koryakova, 32<br /> metro Ozerki</Text>
          </InfoItem>
        </InfoList>
        <Gifts>
          <Title>No boxed gifts</Title>
          <Text size='xs'>
            <i>
              Our big plans for the future won't allow us to take much stuff
              with us, so the best gift is the resources to help us make those
              plans a reality.
            </i>
          </Text>
        </Gifts>
      </Content>
    </Container>
  );
};
