import React from 'react'
import { theme } from '../../constants/common'
import { Navigation } from '../navigation'

export const Layout = ({ children }) => {
  return (
    <div style={{
      background: theme.paletteBg,
    }}>
      <Navigation />
      {children}
    </div>
  )
}