import React from 'react'
import ReactCountdown from 'react-countdown'
import styled from 'styled-components'
import { celebrationDate, MediaScreens, theme } from '../../constants/common';

const Completionist = () => <span>Yeeeeeeah, we're married!</span>;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Title = styled.h2`
  font-family: ${theme.fonts.title};
  font-size: 2em;
  margin: 0;
  margin-bottom: 0.5em;
  text-transform: capitalize;
`

const CountdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacer.xs};

  @media screen and (min-width: ${MediaScreens.tablet}) {
    gap: ${theme.spacer.lg};
  }
`

const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CountdownLabel = styled.span`
  color: ${theme.text};
  font-size: 20px;
`

const CountdownValue = styled.span`
  color: ${theme.text};
  font-size: 54px;
  font-family: ${theme.fonts.title};
`

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <CountdownContainer>
        <CountdownItem>
          <CountdownValue>{days}</CountdownValue>
          <CountdownLabel>Days</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownValue>{hours}</CountdownValue>
          <CountdownLabel>Hours</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownValue>{minutes}</CountdownValue>
          <CountdownLabel>Minutes</CountdownLabel>
        </CountdownItem>
        <CountdownItem>
          <CountdownValue>{seconds}</CountdownValue>
          <CountdownLabel>Seconds</CountdownLabel>
        </CountdownItem>
      </CountdownContainer>
    )
  }
}

export const Countdown = () => {
  return (
    <Container>
      <Title>Countdown</Title>
      <ReactCountdown
        date={celebrationDate}
        renderer={renderer}
      />
    </Container>
  )
}
