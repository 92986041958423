import { createGlobalStyle } from 'styled-components';
import { theme } from '../../../constants/common';
 
const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    color: ${theme.text};
    font-family: ${theme.fonts.text};
  }
`;

export default GlobalStyle;
