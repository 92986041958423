import React from 'react'
import styled from 'styled-components'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Text } from '../../components/text'
import { MediaScreens, theme } from '../../constants/common'
import { Layout } from '../../components/layout'

import Heroes1Photo from '../../assets/heroes/hero-1.png'
import Heroes2Photo from '../../assets/heroes/hero-2.png'
import Heroes3Photo from '../../assets/heroes/hero-3.png'
import Heroes4Photo from '../../assets/heroes/hero-4.png'

import AntonVoskresenskiiPhtoto from '../../assets/guests/anton-voskresenskii.png'
import AnyaEvdokimovaPhoto from '../../assets/guests/anya-evdokimova.png'
import AlexShakhovPhoto from '../../assets/guests/alex-shakhov.png'
import AngelaRoshkaPhoto from '../../assets/guests/angela-roshka.png'
import AlexTretyakovPhoto from '../../assets/guests/alex-tretyakov.png'
import AntonKondratyukPhoto from '../../assets/guests/anton-kondratyuk.png'
import AssiyaKadirovaPhoto from '../../assets/guests/assiya-kadirova.png'
import IlyaGrishakovPhoto from '../../assets/guests/ilya-grishakov.png'
import IlyasMusinPhoto from '../../assets/guests/ilyas-musin.png'
import OlyaVladimirovaPhoto from '../../assets/guests/olya-vladimirova.png'
import MatveyIvanovPhoto from '../../assets/guests/matvey-ivanov.png'
import LeraNezhurovaPhoto from '../../assets/guests/lera-nezhurova.png'
import PashaKsushaPhoto from '../../assets/guests/pasha-ksusha.png'
import KatyaSitnyukPhoto from '../../assets/guests/katya-sitnyuk.png'
import VeraMaliutinaPhoto from '../../assets/guests/vera-maliutina.png'
import NastyaKarakulovaPhoto from '../../assets/guests/nastya-karakulova.png'
import NastyaProkopovichPhoto from '../../assets/guests/nastya-prokopovich.png'
import ValyaProkopovichPhoto from '../../assets/guests/valya-prokopovich.png'
import VadimUsinovPhoto from '../../assets/guests/vadim-usinov.png'
import KateMakarshinaPhoto from '../../assets/guests/katya-makarshina.png'
import OlegDidenkoPhoto from '../../assets/guests/oleg-didenko.png'
import TimKuzminPhoto from '../../assets/guests/tim-kuzmin.jpg'
import { PersonCard } from '../../components/person-card'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const guestList = [
  {
    name: 'Angela Roshka',
    photo: AngelaRoshkaPhoto,
    fact: 'Обожаю кофе и запах сирени,.. действует как лекарство..)) Ничего не понимаю в lT, если что-то не так, сразу нажимаю на все кнопки 😊',
    telegram: 'https://t.me/angelalila',
  },
  {
    name: 'Anton Voskresenskii',
    photo: AntonVoskresenskiiPhtoto,
    fact: 'Наслаждаюсь природой, спортом и шахматами ,не люблю деньги - избавляюсь от них с космической скоростью))',
    phone: '89114023104',
  },
  {
    name: 'Matvey Ivanov',
    photo: MatveyIvanovPhoto,
    fact: 'Мечтаю стать Ильей Муромцем. А ещё умею рычать как Беззубик',
  },
  {
    name: 'Vera Maliutina',
    fact: 'Работала с детьми, ездила на Валаам, ходила по Кавказским горам в советское время. Была на экскурсии у подножия Эльбруса, сфоткалась с Антохой из Реальных пацанов',
    photo: VeraMaliutinaPhoto,
    phone: '89873538829',
  },
  {
    name: 'Alexander Shakhov',
    fact: 'В 3 года спрятал шашлык под подушку',
    photo: AlexShakhovPhoto,
    telegram: 'https://t.me/shakhov_ad',
  },
  {
    name: 'Anya Evdokimova',
    photo: AnyaEvdokimovaPhoto,
    fact: 'В этом году впервые попробовала sup board и это огонь! Готова кататься на них хоть каждые выходные :)',
    telegram: 'https://t.me/amevdokimova',
  },
  {
    name: 'Anastasiya Prokopovich',
    fact: 'Устанавливала первый раз (и последний) палатку на высоте 3700 м  🏔',
    photo: NastyaProkopovichPhoto,
    telegram: 'https://t.me/Anastasia_Prokopovich',
  },
  {
    name: 'Valentin Prokopovich',
    fact: 'Пил масала чай в поезде Нью-Дели - Ченнаи',
    photo: ValyaProkopovichPhoto,
    telegram: 'https://t.me/DrProkopovich',
  },
  {
    name: 'Anton Kondratyuk',
    fact: 'Я спал 30 дней с Вадимом на сдвинутых кроватях',
    photo: AntonKondratyukPhoto,
    telegram: 'https://t.me/Lamprof',
  },
  {
    name: 'Kate Makarshina',
    fact: 'На одном из первых свиданий повела своего будущего мужа на кладбище',
    photo: KateMakarshinaPhoto,
    telegram: 'https://t.me/katiamack',
  },
  {
    name: 'Assiya Kadirova',
    fact: 'Кадырова, дон - вы готовы извиняться?',
    photo: AssiyaKadirovaPhoto,
    telegram: 'https://t.me/assiya_kadyrova',
  },
  {
    name: 'Oleg Didenko',
    fact: 'Умею на гитаре, на сноуборде и на макбуке, а что умеешь ты?',
    photo: OlegDidenkoPhoto,
    telegram: 'https://t.me/Diaglyonok,'
  },
  {
    name: 'Lera Nezhurova',
    fact: 'Тусовалась в шахте, теперь танцую и тверкаю только на поверхности',
    photo: LeraNezhurovaPhoto,
    telegram: 'https://t.me/LeraNEZ',
  },
  {
    name: 'Tim Kuzmin',
    fact: 'Since I called my cat Kotlin we are playing hardcore together',
    photo: TimKuzminPhoto,
    telegram: 'https://t.me/timtimdotqz',
  },
  {
    name: 'Ksenya Smeshnova',
    photo: PashaKsushaPhoto,
    phone: '89117603840',
  },
  {
    name: 'Pavel Smeshnov',
    photo: PashaKsushaPhoto,
    telegram: 'https://t.me/pavelosha'
  },
  {
    name: 'Alexander Tretyakov',
    fact: 'Потерял загран в первый же день в чужой стране',
    photo: AlexTretyakovPhoto,
    telegram: 'https://t.me/backsapce',
  },
  {
    name: 'Olya Vladimirova',
    fact: 'Умею играть на маракасах. Ходят слухи, будто являюсь основателем одного популярного паблика в вк',
    photo: OlyaVladimirovaPhoto,
    telegram: 'https://t.me/lyalyen',
  },
  {
    name: 'Nastya Karakulova',
    fact: 'Родилась в столице автоваза, собираю ладу гранту с закрытыми глазами. Но это не точно',
    photo: NastyaKarakulovaPhoto,
    telegram: 'https://t.me/nastya_echo',
  },
  {
    name: 'Vadim Usinov',
    fact: 'Прямиком из Италии. Из маленького городка Тольятти. Балдею от котиков и грузинского лимонада',
    photo: VadimUsinovPhoto,
    telegram: 'https://t.me/WazZro',
  },
  {
    name: 'Ilya Grishakov',
    fact: 'Я умею касаться языком до носа',
    photo: IlyaGrishakovPhoto,
    telegram: 'https://t.me/cyansmoke',
  },
  {
    name: 'Ilyas Musin',
    fact: 'Молодой Казах. Если в 3 словах: женщины, деньги и бизнес.',
    photo: IlyasMusinPhoto,
    telegram: 'https://t.me/k_ilyasmus',
  },
  {
    name: 'Katya Sitnyuk',
    photo: KatyaSitnyukPhoto,
    fact: 'Работаю «администратором» в администрации',
    phone: '89602351697',
  },
]

const Title = styled(Text)`
  font-family: ${theme.fonts.title};
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`

const Wrapper = styled.div`
  background-color: ${theme.guestsBg};
`

const Container = styled.div`
  display: grid;
  gap: ${theme.spacer.lg};
  max-width: 1200px;
  margin: 0 auto;
  padding: ${theme.spacer.md} ${theme.spacer.xs};
  text-align: center;

  @media screen and (min-width: ${MediaScreens.tablet}) {
    padding: ${theme.spacer.lg};
  }
`

const Header = styled.div`
  display: grid;
  gap: ${theme.spacer.xs};
`

const HeroesList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (min-width: ${MediaScreens.tablet}) {
    flex-direction: row;
    gap: ${theme.spacer.md};
  }
`

const GuestGrid = styled.div`
  column-gap: ${theme.spacer.xs};
  display: grid;
  row-gap: ${theme.spacer.md};
  text-align: center;

  @media screen and (min-width: ${MediaScreens.tablet}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const GuestsPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <Header>
            <Title>Heroes of the occasion</Title>
            <Text>Those guys who annoying you the last few days and always late</Text>
            <HeroesList>
              <LazyLoadImage
                alt='hero-1'
                effect='blur'
                width={156}
                src={Heroes1Photo}
              />
              <LazyLoadImage
                alt='hero-2'
                effect='blur'
                width={156}
                src={Heroes2Photo}
              />
              <LazyLoadImage
                alt='hero-3'
                effect='blur'
                width={156}
                src={Heroes3Photo}
              />
              <LazyLoadImage
                alt='hero-4'
                effect='blur'
                width={156}
                src={Heroes4Photo}
              />
            </HeroesList>
          </Header>
          <Header>
            <Title>Our Guests</Title>
            <div>Here's a page with brief information about our friends who will be sharing this wonderful celebration with us. You can read little facts about them to make it easier for you to understand who's who and what they look like</div>
          </Header>
          <GuestGrid>
            {guestList.map((guest) => (
              <PersonCard key={guest.name.toLowerCase()} {...guest} />
            ))}
          </GuestGrid>
        </Container>
      </Wrapper>
    </Layout>
  )
}
