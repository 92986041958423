import React, { useRef, useState } from "react"
import { useClickAway, useEffectOnce, useToggle } from "react-use"
import { HamburgerCollapse } from "react-animated-burgers"
import { navItems, theme, Transitions } from "../../constants/common"
import { ExternalLink, InternalLink } from "./link"
import styled from "styled-components"
import { Text } from "../text"

const BurgerContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`

const MenuContainer = styled.div`
  background-color: ${theme.text};
  color: #fff;
  height: ${props => props.height};
  position: fixed;
  right: 0;
  top: 0;
  transition: width ${Transitions.common};
  width: ${props => props.isActive ? '200px' : '0px'};
  z-index: 2;
`

const MenuList = styled.div`
  display: grid;
  gap: calc(${theme.spacer.xs} / 4);
`

const MenuItem = styled.div`
  padding: ${theme.spacer.xs} ${theme.spacer.md};
  transition: opacity ${Transitions.common};

  &:hover {
    opacity: 0.5;
  }
`

const useBodyHeight = () => {
  const [height, setHeight] = useState(0)

  useEffectOnce(() => {
    setHeight(document.body.getBoundingClientRect().height)
  })

  return height
}

const CollapseContainer = styled.div`
  display: grid;
  gap: ${props => props.isOpen ? theme.spacer.xs : 0};
  transition: gap ${props => props.isOpen ? Transitions.out : Transitions.common};
`

const CollapseText = styled(Text)`
  color: #eee;
  max-height: ${props => props.isOpen ? '200px' : 0};
  overflow: hidden;
  transition: max-height ${props => props.isOpen ? Transitions.out : Transitions.common};
  transition-delay: .1s;
`

const Collapsable = ({ item }) => {
  const [isOpen, toggle] = useToggle(false)
  const ref = useRef(null)

  useClickAway(ref, () => {
    toggle(false)
  })

  return (
    <CollapseContainer ref={ref} isOpen={isOpen}>
      <ExternalLink href='#' onClick={toggle}>
        {item.name}
      </ExternalLink>
      <CollapseText isOpen={isOpen} size='xs'>{item.placeholder}</CollapseText>
    </CollapseContainer>
  )
}

const renderLink = (item) => {
  return item.external ? (
    <ExternalLink href={item.href} target="_blank" rel="noopener noreferrer">
      {item.name}
    </ExternalLink>
  ) : (
    <InternalLink to={item.href}>{item.name}</InternalLink>
  )
}

export const MobileNavigation = () => {
  const [isActive, toggle] = useToggle(false)
  const menuRef = useRef(null)
  const bodyHeight = useBodyHeight()

  useClickAway(menuRef, () => {
    toggle(false)
  })

  return (
    <>
      <BurgerContainer>
        <HamburgerCollapse
          isActive={isActive}
          toggleButton={toggle}
          barColor={theme.primary}
        />
      </BurgerContainer>
      <MenuContainer isActive={isActive} height={`${bodyHeight}px`}>
        <MenuList ref={menuRef}>
          {navItems.map((item) => (
            <MenuItem key={item.name.toLowerCase()}>
              {item.placeholder ? <Collapsable menuRef={menuRef} item={item} /> : renderLink(item)}
            </MenuItem>
          ))}
        </MenuList>
      </MenuContainer>
    </>
  );
};
