import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import { createRoot } from 'react-dom/client'
import GlobalStyle from './components/global/styles'
import { HomePage } from './pages/home'
import { GuestsPage } from './pages/guests'
import { Toaster } from 'react-hot-toast'
import { MediaScreens, theme } from './constants/common'
import { Layout } from './components/layout'
import { Text } from './components/text'

const Wrapper = styled.div`
  background-color: ${theme.guestsBg};
  height: 100vh;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacer.lg};
  height: 100%;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${theme.spacer.md} ${theme.spacer.xs};
  text-align: center;

  @media screen and (min-width: ${MediaScreens.tablet}) {
    padding: ${theme.spacer.lg};
  }
`

const Header = styled.div`
  display: grid;
  gap: ${theme.spacer.xs};
`

const Title = styled(Text)`
  font-family: ${theme.fonts.title};
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`

const Body = styled.div`
  max-width: 450px;
  margin: 0 auto;
`

const Table = styled.table`
  border-collapse: separate;
  border-spacing: ${theme.spacer.xs};
  margin-left: ${theme.spacer.lg};
`

const TableColumn = styled.td`
  font-weight: bold;
  text-align: left;
  padding: 8px;

  &:nth-child(odd) {
    border-right: 1px solid;
    padding-right: ${theme.spacer.md};
  }

  &:nth-child(even) {
    padding-left: ${theme.spacer.xs};
  }
`

const TableRow = styled.tr`
  margin-bottom: ${theme.spacer.xs};
`

const TimingPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Container>
          <Header>
            <Title>Тайминги</Title>
          </Header>
          <Body>
            <Table>
              <tbody>
                <TableRow>
                  <TableColumn>15:00</TableColumn>
                  <TableColumn>Сбор гостей</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>15:30</TableColumn>
                  <TableColumn>Мастер-класс</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>17:00</TableColumn>
                  <TableColumn>Церемония</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>19:30</TableColumn>
                  <TableColumn>Ужин</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>21:30</TableColumn>
                  <TableColumn>Завершающая часть</TableColumn>
                </TableRow>
                <TableRow>
                  <TableColumn>22:00</TableColumn>
                  <TableColumn>Чилл</TableColumn>
                </TableRow>
              </tbody>
            </Table>
          </Body>
        </Container>
      </Wrapper>
    </Layout>
  )
}

function App() {
  return (
    <Router basename="/">
      <GlobalStyle />
      <Routes>
        <Route path='/guests' element={<GuestsPage />} />
        <Route path='/timing' element={<TimingPage />} />
        <Route path='/*' element={<HomePage />} />
      </Routes>
      <Toaster
        position='top-right'
        reverseOrder={false}
        toastOptions={{
          style: {
            fontFamily: theme.fontFamily,
            fontSize: "20px",
            lineHeight: "24px",
          },
        }}
      />
    </Router>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(<App />);
