import styled from 'styled-components'
import { theme } from '../../constants/common'

export const TextSizes = {
  'xs': '0.85em',
  'md': '1.15em',
  'lg': '1.5em',
  'xl': '2em',
}

export const Text = styled.p`
  color: ${theme.text};
  font-family: ${theme.fonts.text};
  font-size: ${props => TextSizes[props.size] ?? TextSizes.md};
  margin: 0;
`