import React from 'react'
import { FaEnvelopeOpenText } from 'react-icons/fa'
import styled from 'styled-components'
import { theme } from '../../constants/common'
import { Text } from '../text'

const Container = styled.div`
  display: grid;
  gap: ${theme.spacer.md};
  padding: ${theme.spacer.md};
`

const HeaderContainer = styled.div`
  display: grid;
  gap: calc(${theme.spacer.xs} / 2);
  justify-content: center;
  text-align: center;
`

const Title = styled(Text)`
  font-family: ${theme.fonts.title};
  font-weight: 700;
`

const Content = styled.div`
  box-shadow: 0 1px 5px rgba(0,0,0,.08);
  display: grid;
  gap: ${theme.spacer.xs};
  margin: 0 auto;
  max-width: 850px;
  padding: ${theme.spacer.md};
`

const StyledText = styled(Text)`
  font-family: ${theme.fonts.title};
`

export const RSVP = () => {
  return (
    <Container>
      <HeaderContainer>
        <div><FaEnvelopeOpenText color={theme.primary} size='2em' /></div>
        <Title>RSPV</Title>
        <Text size='xs'>20.06.2022</Text>
      </HeaderContainer>
      <Content>
        <StyledText>
          Are you planning to stay the night? A BBQ and chillout is
          planned for the second day.
        </StyledText>
        <StyledText>
          Will you be driving to the venue by private transportation or
          shuttle?
        </StyledText>
        <StyledText>
          Will there be a +1 with you?
        </StyledText>
        <StyledText>
          Do you have any allergies or peculiarities that we should be
          aware of?
        </StyledText>
        <StyledText>
          Interesting fact and photo (We would like to introduce our
          guests a little in advance, so please send your photo and an
          interesting or funny fact that we can publish on this site)
        </StyledText>
      </Content>
    </Container>
  )
}
