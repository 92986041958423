import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { FaUserAlt, FaPhone, FaTelegram } from 'react-icons/fa'
import styled from 'styled-components'
import { theme } from '../../constants/common'
import { Text } from '../text'

const Container = styled.div`
  display: grid;
  gap: 4px;
  grid-template-rows: 128px 30px auto;
`

const AvatarWrapper = styled.div`
  border: 2px solid ${theme.primary};
  border-radius: 50%;
  height: ${theme.avatarSize}px;
  margin: 0 auto;
  overflow: hidden;
  width: ${theme.avatarSize}px;
`

const Grayscale = styled.div`
  filter: grayscale(1);
`

const Name = styled(Text)`
  font-weight: bold;
`

const Fact = styled(Text)``

export const PersonCard = ({ name, fact, photo, telegram, phone }) => {
  return (
    <Container>
      <AvatarWrapper>
        <Grayscale>
          {photo ? (
            <LazyLoadImage
              alt={name}
              effect="blur"
              height={theme.avatarSize}
              src={photo}
              width={theme.avatarSize}
            />
          ) : (
            <div style={{
              alignItems: 'flex-end',
              display: 'flex',
              height: theme.avatarSize,
              justifyContent: 'center',
              width: theme.avatarSize,
            }}>
              <FaUserAlt size={theme.avatarSize - 16} />
            </div>
          )}
        </Grayscale>
      </AvatarWrapper>
      <Name>{name}</Name>
      <Fact size='xs'>{fact}</Fact>
      {telegram && (
        <a
          href={telegram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegram color={theme.primary} size={24} />
        </a>
      )}
      {phone && <a href={`tel:${phone}`}><FaPhone color={theme.primary} /></a>}
    </Container>
  )
}
