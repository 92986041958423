import React from 'react'
import styled from 'styled-components'
import { MediaScreens, theme } from '../../constants/common'
import { Text } from '../text'

const TaskContainer = styled.div`
  background-color: ${theme.tasksBg};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacer.xs};
  height: 100%;

  @media screen and (min-width: ${MediaScreens.tablet}) {
    margin: 0 auto;
    max-width: 850px;
    padding: ${theme.spacer.lg};
  }
`

export const CommonTask = () => {
  return (
    <div style={{
      display: 'grid',
      gap: theme.spacer.md,
      padding: theme.spacer.md,
    }}>
      <Text size='xl' style={{ fontWeight: 700, fontFamily: theme.fonts.title, textAlign: 'center' }}>Task</Text>
      <TaskContainer>
        <Text>
          We would also like to ask you to prepare a small joint greeting in
          any format (video, dance, text, etc.) that you could present at
          the party.
        </Text>
        <Text>
          You can use your imagination and make whatever you want, we are
          sure we have the best and most creative friends.
        </Text>
        <Text size="xs">
          If you need any equipment or help, please contact us.
        </Text>
      </TaskContainer>
    </div>
  )
}
