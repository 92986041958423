export const hexToRgba = (hex, opacity) => {
  const colors = []
  const withoutHash = hex.replace('#', '')
  for (let i = 0; i < withoutHash.length; i += 2) {
    const num16 = withoutHash.slice(i, i + 2)
    const num10 = parseInt(num16, 16)
    colors.push(num10)
  }
  return `rgba(${colors.join(', ')}, ${opacity})`
}

export const BG_OPACITY = 0.35

export const theme = {
  avatarSize: 128,
  borderColor: '#fff0e0',
  primary: '#C2AD8D',
  secondary: '#f8c291',
  guestsBg: '#fff9f0',
  detailsBg: hexToRgba('#f0ffff', BG_OPACITY),
  paletteBg: hexToRgba('#fff6f0', BG_OPACITY),
  countdownBg: hexToRgba('#fff5fd', BG_OPACITY),
  tasksBg: hexToRgba('#fcfffc', BG_OPACITY),
  fonts: {
    title: "'Gentium Plus', sans-serif",
    text: "'Lato', sans-serif",
  },
  text: '#626B65',
  spacer: {
    xs: '16px',
    md: '32px',
    lg: '64px',
  }
}

export const MediaScreens = {
  tablet: '768px'
}

export const Transitions = {
  common: '.2s ease-in',
  out: '.2s ease-out',
}

export const celebrationDate = new Date('2022-07-09T14:00:00')

export const navItems = [
  { href: '/', name: 'Главная' },
  { href: '/', name: 'Трансфер', placeholder: 'Поскольку место проведения нашей вечеринки находится недалеко от метро Озерки (~20 минут пешком), мы решили перераспределить деньги на более веселые вещи. Надеемся, вы поняли и подготовили свой маршрут.' },
  { href: '/timing', name: 'Тайминги' },
  { href: '/guests', name: 'Гости' },
  { href: '/', name: 'Фото', placeholder: 'Здесь будут размещены фотографии с мероприятия, когда фотограф пришлет их нам.' },
  { href: '/', name: 'Взять с собой', placeholder: 'У нас есть сауна и бассейн, принесите свои полотенца, тапочки, купальные костюмы. Вы можете остаться на ночь, поэтому вам нужна пижама. Don\ не дарите цветы, их будет много.' },
  { href: 'https://pin.it/1KGgaYB', external: true, name: 'Мудборд' },
]
