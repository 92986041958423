import React from 'react'
import { navItems } from '../../constants/common'
import { ExternalLink, InternalLink } from './link'

export const DesktopNavigation = () => {
  return (
    <div>
      {navItems.map((item) => (
        <div key={item.name.toLowerCase()}>
          {item.external ? (
            <ExternalLink href={item.href} target="_blank" rel="noopener noreferrer">{item.name}</ExternalLink>
          ) : (
            <InternalLink to={item.href}>{item.name}</InternalLink>
          )}
        </div>
      ))}
    </div>
  )
}
