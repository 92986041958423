import React from 'react'
import { FaPhone, FaTelegram } from 'react-icons/fa'
import styled from 'styled-components'
import { theme } from '../../constants/common'
import { Text } from '../text'

const Container = styled.div`
  display: grid;
  gap: ${theme.spacer.xs};
  text-align: center;
`

const Content = styled.div`
  align-items: center;
  display: flex;
  gap: ${theme.spacer.md};
  justify-content: center;
`

const ContactItem = styled.div`
  align-items: flex-end;
  display: flex;
  gap: ${theme.spacer.xs};
`

export const Contacts = () => {
  return (
    <Container>
      <Text size="xs">
        If you have any questions or need help, we are always in touch.
      </Text>
      <Content>
        <ContactItem>
          <Text style={{ marginBottom: "2px" }}>Luchika</Text>
          <a
            href="https://t.me/Luchanaa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram color={theme.primary} />
          </a>
          <a href="tel:89045128817"><FaPhone color={theme.primary} /></a>
        </ContactItem>
        <ContactItem>
          <Text style={{ marginBottom: "2px" }}>Vadim</Text>
          <a
            href="https://t.me/mfpjke"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram color={theme.primary} />
          </a>
          <a href="tel:89810155391"><FaPhone color={theme.primary} /></a>
        </ContactItem>
      </Content>
    </Container>
  )
}
