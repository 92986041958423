import React from 'react'
import styled from 'styled-components'
import invitationSrc from '../../assets/invitation.webp'
import { theme } from '../../constants/common'
import { Text } from '../text'

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: center;
  padding: ${theme.spacer.xs};
  position: relative;
  text-align: center;
`

const Background = styled.div`
  background-image: url(${invitationSrc});
  background-size: cover;
  background-repeat: no-repeat;
  filter: opacity(0.25) saturate(0.25);
  height: 100%;
  position: absolute;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-transform: capitalize;
  font-family: ${theme.fonts.title};
`

const Heading = styled.h1`
  font-size: 2em;
  filter: opacity(1.75) saturate(1.75);
  margin: 0;
`

const StyledText = styled(Text)`
  font-family: ${theme.fonts.title};
  font-weight: bold;
`

export const Invitation = () => {
  return (
    <Container>
      <Background />
      <Content>
        <Heading>Dear Friends</Heading>
        <StyledText size='lg'>We would like to invite you to our wedding</StyledText>
        <StyledText>We want to spend this day with our closest people</StyledText>
        <StyledText>whom we love and appreciate</StyledText>
      </Content>
    </Container>
  )
}
