import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from '../text';
import { MediaScreens, theme } from '../../constants/common'

const TaskContainer = styled.div`
  background-color: ${theme.tasksBg};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacer.xs};
  height: 100%;
  padding: ${theme.spacer.md};

  @media screen and (min-width: ${MediaScreens.tablet}) {
    margin: 0 auto;
    max-width: 850px;
    padding: ${theme.spacer.lg};
  }
`

const tasks = {
  storytell: (
    <TaskContainer>
      <Text>
        They say that Mercury retrograde has a negative impact on life.
      </Text>
      <Text>
        Whether it's true or not, we don't know,{" "}
        <b>
          but astrologers believe that July 9 is a favorable day to meet with
          friends, eat good food, dance and love.
        </b>
      </Text>
      <Text>
        This is a forecast for just one day. But we want to know what will
        happen in the future.
      </Text>
      <Text>
        So, our dear astrologers Anya and Sasha, we ask you to make astrological
        forecast for our family in Power Point presentation format.
      </Text>
      <Text>
        It will take 5-10 minutes. The format and the presentation depends on
        you.
      </Text>
      <Text>🙂 Good luck!</Text>
      <Text size="xs">
        P.S. We will have a projector to show this presentation on. A little
        later we will write the approximate timing of the event and your
        presentation time.
      </Text>
    </TaskContainer>
  ),
  justdance: (
    <TaskContainer>
      <Text>
        We have so many talented friends that we just have to ask you to perform
        at our wedding.
      </Text>
      <Text>
        Dear Oleg, Asya, Tim and Lera, we would like to give you a small wedding
        assignment to prepare a creative number in song format with choreography
        or in any other format that you feel close to.
      </Text>
      <Text>🙂 Good luck!</Text>
      <Text size="xs">
        P.S. If you will need any equipment or help, please contact us.
        Approximate time is 5-10 minutes, but if you need more time, please
        email. We will send a timing of the event and your performance time a
        little later.
      </Text>
    </TaskContainer>
  ),
  olympicgames: (
    <TaskContainer>
      <Text>
        Dear Valya and Nastya, our wedding will be attended only by our closest
        people, each of whom is very dear to us.
      </Text>
      <Text>
        Almost all of us know each other, so a warm and cordial atmosphere is
        guaranteed. But what is a wedding without interesting memories and
        contests.
      </Text>
      <Text>
        So we ask our Moscow friends to share what they do now at weddings in
        the capital and give us a little demonstration.
      </Text>
      <Text>
        <b>Top 5 wedding contests.</b>
      </Text>
      <Text>
        These can be classic or on the contrary very strange contests, to your
        choice.
      </Text>
      <Text>It will be your little wedding task</Text>
      <Text>🙂 Good luck!</Text>
      <Text size="xs">
        P.S. If you need any equipment or help, please contact us. We'll send
        you timings and approximate times for the activities later.
      </Text>
    </TaskContainer>
  ),
}

export const PersonalTasks = () => {
  const { taskId } = useParams()
  const task = tasks[taskId]
  return task
}
