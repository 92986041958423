import React from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../constants/common'
import { Palette } from '../../components/palette'
import { Invitation } from '../../components/invitation'
import { Countdown } from '../../components/countdown'
import { CommonTask, PersonalTasks } from '../../components/tasks'
import { Details } from '../../components/details'
import { Text } from '../../components/text'
import { Contacts } from '../../components/contacts'
import { RSVP } from '../../components/rsvp'
import { Layout } from '../../components/layout'
import { YMaps, Map, Placemark, ZoomControl, GeolocationControl } from "react-yandex-maps"

const PaletteSection = styled.section`
  background-color: ${theme.paletteBg};
  padding: ${theme.spacer.lg} ${theme.spacer.md};
`

const placeLocation = [60.049455, 30.307289]

export const HomePage = () => {
  return (
    <Layout>
      <section id="invitation">
        <Invitation />
      </section>
      <section id="details">
        <Details />
      </section>
      <section
        id="countdown"
        style={{ background: theme.countdownBg, height: "300px" }}
      >
        <Countdown />
      </section>
      <section id="common-task" style={{ background: theme.paletteBg }}>
        <CommonTask />
      </section>
      <section id="rsvp" style={{ background: theme.detailsBg }}>
        <RSVP />
      </section>
      <Routes>
        <Route
          path="/task/:taskId"
          element={
            <section id="personal-task" style={{ marginTop: theme.spacer.md }}>
              <Text
                style={{
                  textAlign: "center",
                  fontFamily: theme.fonts.title,
                  fontWeight: 700,
                }}
                size="lg"
              >
                Personal Task
              </Text>
              <PersonalTasks />
            </section>
          }
        />
      </Routes>
      <PaletteSection id="palette">
        <Palette />
      </PaletteSection>
      <section id="contacts" style={{ padding: theme.spacer.md }}>
        <Contacts />
      </section>
      <section id="map" style={{ height: "400px", width: "100%" }}>
        <YMaps>
          <Map
            height="400px"
            width="100%"
            defaultState={{
              center: placeLocation,
              zoom: 12,
            }}
          >
            <Placemark defaultGeometry={placeLocation} />
            <GeolocationControl options={{ position: { right: 60, top: 30 } }} />
            <ZoomControl options={{ position: { right: 60, top: 90 } }} />
          </Map>
        </YMaps>
      </section>
    </Layout>
  );
}
